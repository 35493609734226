import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import june_img_1 from '../../../images/June-Gipson-hero.jpg';
import june_img_1_mobile from '../../../images/June-Gipson-hero-mobile.jpg';

const fullName = "June Gipson";

const copyList = [

    {
        type: "image",
        content: {
            src: june_img_1,
            mobileSrc: june_img_1_mobile,
            alt: "Text reads The Changemaker over a woman looking confidently into camera",
            caption: "Text reads The Changemaker over a woman looking confidently into camera"
        }
    },
    {
        type: "text",
        content: "<span class=\"h3\">A conversation with Dr June Gipson, CEO of My Brother’s Keeper, an organization dedicated to reducing health disparities nationwide</span>"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Changemaker"
    // },

    {
        type: 'text',
        content: `When the going gets tough, Dr June Gipson gets going to create positive change. “I’m the poster child 
        of coming out on top of a challenge,” says Dr Gipson, Chief Executive Officer at 
        My Brother’s Keeper, Inc.,* a Mississippi-based nonprofit with a history of reducing health disparities 
        throughout the United States. She also sits on the AIDS United board of trustees, but long before she held any 
        of those high-ranking titles, “I wanted to be President,” she says. “I just knew I wanted to be in leadership.”`
    },

    {
        type: 'text',
        content: "In the early 2000s, she took a position at Jackson State University, where she worked on a CDC-funded program helping strengthen HIV prevention organizations. “This was my entry into the LGBTQ community,” she explains. “I learned the world of Black gay men, the most wonderful group of people I had ever encountered.” But with those personal connections came consistent heartbreak. “In just three years, I remember thinking, ‘I’ve lost so many people.’ Those deaths were the thing that woke me up to how bad it was. And that something had to change.”"
    },
    {
        type: "text",
        content: "Dr Gipson chose to be that change, first creating and refining curricula for other HIV/AIDS organizations across the country, helping them lead with best practices, research, and knowledge to better serve their communities. She also went to work securing resources for necessary initiatives and programming, including at one point helping save My Brother’s Keeper from dissolution. She’s also committed to calling out inequality in the HIV landscape. “I’m a pretty blunt person,” she says. When the HIV epidemic began ravaging the Black community, “I remember telling a white gay man at the health department, ‘This is not just your disease anymore. This is what’s happening to us right now, and you’re going to have to put the money where it needs to go.’” Says Dr Gipson, “I will fight for what needs to happen.”"
    },
    {
        type: "blockquote",
        content: "“I will fight for what needs to happen.”",
        author: fullName
    },
    {
        type: "text",
        content: "That said, it’s a tough fight: “This work is mentally, physically, and emotionally quite draining,” she says. But when asked what she does to de-stress, “I start pondering what else I can do to make things better,” she says. “When it gets heavy, I need to solve things. If I just keep changing things here, there, things will get better.” Armed with that philosophy, she launches one innovative program after another at My Brother’s Keeper, like recently putting state-of-the-art HIV testing kiosks in women’s shelters. “They’ve had trauma and don’t want to go to clinics, so we’re turning domestic violence shelters into telehealth centers.” While she’s aware of her limitations, she refuses to limit herself. “I didn’t break the system, so it’s going to be really hard for me to fix it, but I can create change,” says Dr Gipson."
    },
    {
        type: "disclaimer",
    },

    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">DR. JUNE GIPSON"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">My Brother’s Keeper, Inc."
    // },
    {
        type: "text",
          content: "<a class=\"button large secondary\" href=\"/championsofchange\">Learn more about all our Champions of Change leaders"

    },

]
class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}
        ariaLabel="Group of smiling Black women in baroque clothing sitting together in front of a purple velvet curtain"
        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Dr June Gipson, The Changemaker"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}
      

      </div>
    );
  }
}

export default Content;
